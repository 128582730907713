.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 330px;
  height: 38px;
  padding: 5px 5px;
  border: 1px solid #aaa;
  border-radius: 2px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 330px;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  overflow: hidden;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@media (max-width: 575.98px) {
  .react-autosuggest__input {
    width: 100%;
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
  }
}