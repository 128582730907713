.TransactionStats ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1px;
    border-bottom: 1px solid #e6e9ee;
    padding-bottom: 0px;
}
.TransactionStats dl {
    margin-bottom: 0;
    text-align: right;
}
.TransactionStats dt {
	float: left;
  font-weight: 400;
  line-height: 30px;
}
.TransactionStats dd {
    line-height: 30px;
    margin-left: 0;
}