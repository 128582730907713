.Footer {
  background-color:  #d1ecf1;
  color: #106ba3;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  line-height: 2;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto!important;
}
hr.Separator {
  border-top: 1px solid #99c0da;
}
.footer-link {
  text-decoration-color: #106ba3;
  color: #106ba3;
}
.footer-link[href]:hover {
  color: #106ba3;
}