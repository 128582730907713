/*
* Default XBadge style
*/
.xbadge {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  min-width: 20px;
  max-width: 100%;
  min-height: 20px;
  padding: 2px 6px;
  line-height: 16px;
  font-size: 0.8rem;
  white-space: nowrap;
/*color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
*/
}