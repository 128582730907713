body {
  overflow-y: scroll;
}
.App {
  background-color: #f9fbff;
	padding-top: 24px;
  font-size: 0.87rem;
}
.container {
  max-width: 1420px;
}
a, a:hover {
    color: #106ba3;
}
.card {
	margin-bottom: 24px;
	/*border: 0;*/
}
.card-body {
	padding: 15px;
}
.card li > a {
  border-radius: 5px;
}
.money {
	font-family: "Droid Sans Mono", Menlo, Monaco, Consolas, "Courier New", "monospace";
}
.hash {
  font-family: "Droid Sans Mono", Menlo, Monaco, Consolas, "Courier New", "monospace";
}
.address-tag {
	width: 105px;
	display: inline-block;
  vertical-align: bottom;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.address-tag-lg {
  width: 230px;
}
.nft-truncate {
  max-width: 200px;
}
.url-truncate {
  max-width: 256px;
}
.ledger-tag a {
  color: #A82A2A;
}
.data-row {
	border-top: 1px solid #ddd;
}
.data-header {
	border-bottom: 0;
}
.word-break {
  word-break: break-all;
}
.graph-key {
  width: 180px;
}
.address-name {
  overflow: hidden;
  white-space:nowrap;
}
.account-details-tab {
  margin-bottom: 0;
  margin-left: auto;
}
.metrics-tab {
  margin-bottom: 0;
  margin-left: auto;
}
.remove-card-header-tabs {
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.accountinfo-balance {
  color: #212529;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.badge-verified {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.badge-large {
  font-size: 95%;
}
.partial-payment-tag {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.metric-card {
  padding: 5px;
}
.metric-card-number {
  font-weight: 400;
}
.metric-card-icon {
  margin-bottom: 5px;
}
.ledger-arrow-left {
  padding-right: 10px;
}
.ledger-arrow-right {
  padding-left: 10px;
}
.btn-votes {
  padding: 0.0rem 0.5rem;
  line-height: 0;
}
.breadcrumb {
  flex-wrap: inherit;
}
.link-plain {
  text-decoration: none;
}
.link-plain a:hover {
  text-decoration: none;
}

/*
* Styles for Dimming animation when a field's value changes.
*/
.highlight {
  animation: highlight 1000ms ease-out;
};
@keyframes highlight {
  from {
    background-color: #fff3cd;
  }
}

/* Media query for small devices
*  add 'panel-body-xs' style to 'panel-body'
*/
@media (max-width: 575.98px) {
  .card-body.card-body-xs {
    padding: 0;
  }
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .card-body .card-title {
    padding: 0 10px;
  }
  .TransactionStats ul {
      padding: 0 10px;
  }
  .navbar-expand {
    flex-direction: column;
  }
  .navbar-brand {
    margin: 10px 0;
    padding: 0;
  }
  .search-wrapper {
    flex-flow: unset;
  }
  .search-wrapper .react-autosuggest__input {
    border-radius: 4px 0 0 4px;
    padding: 0 10px;
  }
  .search-wrapper .btn-info {
    border-radius: 0 4px 4px 0;
  }
  .alert {
    border-radius: 0;
  }
}